/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";

@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin boxSizing($attr){
    box-sizing:  $attr;
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    -ms-box-sizing: $attr;
    -o-box-sizing: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin andplaceholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin clearfix{
    &:before,
    &:after{
        content: " "; 
        display: table; 
    }
    &:after{
        display: block; 
        clear: both;
        height: 0px;
        margin-top: -1px;
        visibility: hidden;
    }
    &{
        *zoom: 1;
    }
}
@mixin transformorigin($attr){
    -webkit-transform-origin: $attr;
    -moz-transform-origin: $attr;
    -o-transform-origin: $attr;
    transform-origin: $attr;
}


/* color global
----------------------------------------------------------------------------------------------*/
$c_111: #111;
$c_333: #333;
$c_3a3: #3a3a3a;
$c_3a4: #333a43;
$c_555: #555;
$c_5c5: #5c5c5c;
$c_666: #666;
$c_999: #999;
$c_ea: #eaeaea;
$c_e1e: #e1e1e1;
$c_ccc: #ccc;
$c_cfc: #cfcfcf;
$c_ddd: #ddd;
$c_d1d: #d1d1d1;
$c_d6d: #d6d6d6;
$c_fff: #fff;
$c_f1f: #f1f1f1;
$c_f5f: #f5f5f5;
$c_e4e: #e4e4e4;
$c_aca: #acacac; 
$c_a9a: #a9a9a9;
$c_e9e: #e9e9e9;
$c_9f9: #9f9f9f;
$c_959: #959595;
$c_ddd: #dddddd;
$c_eee: #eee;
$c_749: #74927b;
$c_7fc: #7fc8ad;
$c_bfe: #bfe4d6;
$c_9c9: #9c9c34;
$c_a6a: #a6a62b;
$c_aab: #aabbd1;
$c_aca: #acacac;
$c_yellow: #ecbb02;
$c_yellow_2: #e0b206;
$c_yellow_3: #f9eab3;
$c_yellow_4: #f7e49a;
$c_yellow_5: #f4c204;
$c_green: #00453f;
$c_green_2: #a8a838;
$c_green_3: #42e2c6;
$c_green_4: #245e59;
$c_green_5: #8bad25;
$c_green_6: #6c9400;
$c_green_7: #7bdcb9;
$c_green_8: #dce6bd;
$c_green_9: #e8efd3;
$c_green_10: #004843;
$c_e8f: #e8fffd;
$c_e0f: #e0f0ef;
$c_003: #003733;
$c_004: #004943;
$c_c7c: #c7c786;
