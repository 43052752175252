/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1023px) {

	.wrapper, .container {width: 690px;}

	header {
		height: 84px;
		.logo {width: 138px; height: 57px; padding: 14px 0 0 0;}
		.fr {
			nav {display: none;}
		}
		.menu-top {
			margin-top: 19px; float: left;
			.lang {
				.act:after {top: -3px;}
			}
			a {
				height: 44px; line-height: 44px; padding: 0 20px;
				&:last-child {
					@include borderRadius(30px);
				}
			}
		}
		&.float {
			height: 84px;
			.logo {
				width: 138px; height: 57px; padding: 14px 0 0 0;
			}
			.menu-top {
				margin-bottom: 0;
				a {font-size: 13px;}
				.lang {
					.act:after {}
				}
			}
		}
		.burger-menu {
			display: block;
		}
	}

	.nav-mobile {
		.navmob {
			ul {
				padding: 50px 0 30px 0;
				li {
					a {font-size: 28px; padding: 15px;}
				}
			}
		}
	}

	.col-box {
		h3 {
			&.v2 {font-size: 22px;}
		}
	}

	.homeslider {
		.caption {width: 690px;}
		.img {
			height: auto;
		}
	}
	.col-logo {
		.row {
			.col {margin: 0 15px; width: auto;}
		}
	}
	.home-about {
		overflow: hidden;
		&:before {
			position:absolute; width: 100%; height: 100%; background:rgba(127,200,173, 0.7); content: "";
			left: 0; top: 0; z-index: 15;
		}
		.container {z-index: 16; padding: 70px 0;}
		.bgimg {background-position: 100px 0 !important;}
		.bg_shd {left: -15%;}
	}
	.listing-news {
		.container {padding: 70px 0;}
		.row {
			.col {
				width: 30.6%; margin: 0 4% 4% 0;
				&:nth-child(4n+4) {display: none;}
				&:nth-child(3n+3) {margin-right: 0;}
			}
		}
		&.v2 {
			.row {
				.col {
					&:nth-child(4n+4) {margin-right: 4%; display: block;}
					&:nth-child(3n+3) {margin-right: 0;}
				}
			}
		}
		&.carousel {
			.row {
				.col {
					&:nth-child(4n+4) {margin-right: 4%; display: block;}
					&:nth-child(3n+3) {margin-right: 4%;}
				}
			}
		}
	}
	.box-news {
		.row {
			.col {
				.text {
					padding: 25px;
					.title {font-size: 18px; line-height: 22px;}
				}
				&.large {
					.text {
						.title {font-size: 35px; line-height: 40px;}
					}
					&.vids {
	                    a {
	                        &:before {
	                            width: 68px; height: 51px; content: "";
	                        }
	                    }
	                }
				}
			}
		}
	}

	.home-testi {
		.container {
			min-height: 300px; padding: 75px 0;
			&:after {display:none;}
		}
		.txt-testi {
			padding: 25px 45px 25px 45px;
			.ts-title {
				margin-bottom: 10px; height: auto;
				&:after {width: 30px; height: 30px; left: -40px;}
			}
			.ts-des {margin-bottom: 20px;}
		}
		.slick-slider {
			height: auto; margin:0 65px 0 65px;
			.slick-dots {
				bottom: -30px; padding:0 0 0 0 !important;
				text-align: center;
				li {
					button {width: 12px; height: 12px;}
				}
			}
		}
	}
	.home-cta {
		height: 280px;
		.container {padding: 79.5px 0;}
		.bgimg.v2 {
			background-position: -380px 0 !important;
			background-size: auto 100% !important;
		}
		.text {
			width: 340px;
			h3 {font-size: 24px; line-height: 30px; margin-bottom: 25px; padding-bottom: 0;}
		}
		.bg_shd {right: 35%; background-size: cover;}
	}
	.home-address {
		.col {
			&.des {width: 100%; padding:0 0 40px 0;}
			&.address {
				width: 100%; padding: 0;
				.row {
					@include clearfix();
					.item {
						width: 26.9%; float: left; margin: 0 3% 0 0;
						&:nth-child(3n+3) {margin-right: 0;}
					}
				}
			}
		}
	}

	.middle {padding-top:84px;}

	.box-title {
		min-height: 300px;
		.container {padding: 50px 0 30px 0;}
		h1 {font-size: 28px; line-height:34px;}
		&.v2 {
			min-height: 150px; height: 150px;
			.container {padding: 50px 0 37px 0;}
		}
	}

	.mid-content {
		.banner-slider {
			margin: -277px 0 90px 0;
			.caption {font-size: 24px; line-height: 30px;}
		}
		.banner-testimoni {
			margin: -277px 0 90px 0;
			.caption {font-size: 24px; line-height: 30px;}
		}
		.description {
			h3 {
				font-size: 35px; line-height: 42px;
				&.v2 {font-size: 28px; line-height: 30px;}
			}
			h4 {font-size: 18px;}
			.photo-profile {
				.col {
					&.text {
						padding: 10px 0 0 30px;
						&.v2 {padding-top: 15px; padding-right: 190px;}
					}
				}
			}
			&.nostyled-table {
				table {width: 70%;}
			}
			.col-bottom {
				.row {
					&:before {left:44%;}
					.cl {
						&.fl {width: 45%; padding-right: 33px;}
						&.fr {width: 55%; padding-left: 33px;}
					}
				}
			}
		}
		.grid-category {
			.row {
				.col {
					.caption {font-size: 18px; line-height: 22px;}
					&.first {
						.caption {font-size: 18px; padding: 0 20px;}
					}
				}
			}
		}
		.box-landing {
			.row {
				.col {
					&.txt {padding: 35px 40px 0 0;}
					.land-banner {
						.caption {
							&.v2 {
								font-size: 22px; line-height: 26px;
								a {font-size: 16px; line-height: 16px;}
							}
						}
					}
				}
			}
		}
		.grid-category {
			&.col_3 {
				.row {
					.col {
						.caption {
							padding: 0 12px;
							/*.des {display: none; opacity: 0;} */
						}
					}
				}
			}
		}
		.list-gen {
			.col {
				.fr {
					.btn {font-size: 12px;}
				}
			}
		}
	}

	.slick-dots {bottom: 20px;}
	.tab-container {
		.nav-tab {
			.slick-list {margin: 0 35px;}
			ul {
				li {
					margin-bottom: 15px;
					&:after {top:13px;}
					a {
						font-size: 15px; padding: 10px 7px 10px 7px;
					}
				}
			}
		}
	}

	.col-gallery {
		.row {
			.col {
				width: 48%; margin: 0 3% 3% 0;
				&:nth-child(3n+3) {margin-right: 3%;}
				&:nth-child(2n+2) {margin-right: 0;}
				a {
					.img {
						img {width: 100%;}
						.hov_btn {
							opacity: 1; @include transform(scale(1));
							i { min-width: 180px;}
						}
					}
				}
			}
		}
	}

	.col-form {
		.container {padding:55px 0;}
		p {font-size: 14px; line-height: 24px;}
		.form-std {
			.col {
				padding: 0 22px 0 0;
				&:nth-child(2n+2) {padding-left: 22px;}
	            &.with-padding{
	            	padding-left:22px;
	            }
	            &.no-padding{
	            	padding:0;
	            }

			}
			&.std-2 {
				.col {
					&.col_7 {
						width: 95%;
					}
				}
			}
		}
	}

	.inner-contact {
		padding: 0 0;
		.list-address {
			.row {
				.col {
					padding: 0 0 0 0;
					&:nth-child(1) {width: 100%; padding: 0;}
					&:nth-child(2) {width: 100%; padding: 0;}
					.row {
						.cl {
							&:nth-child(1) {width: 45%;}
							&:nth-child(2) {width: 55%;}
						}
					}
				}
			}
		}
		.addmission {
			padding: 60px 0 20px 0;
			.row {
				.col {
					width: 45%;
					&:nth-child(2n+2) {width: 55%; padding-left: 30px;}
				}
			}
		}
		.list-map {
			.row {
				.col {
					.map {
						height: 300px;
						iframe{ height: 300px; }
					}
				}
			}
		}
	}

	.col-acc {
		.acclist {
			.acc-title {line-height: 18px;}
		}
	}
	.careermap {
		.map-location {margin: 0;}
		.des-map {
			.title {padding: 20px 30px 30px 30px;}
		}
		.point-map a {
			&.jkt {left: -40px;}
			&.bali {right: 210px;}
		}
	}


	#boxPop {
		min-width: 90%; max-width: 90%;
		.popArrow {
			width: 60px;
			&.prev {left: 0;}
			&.next {right: 0;}
		}
		.content_pop {
			.vid {height: 375px;}
		}
	}

	#boxPop-2 {
		min-width: 80%; max-width: 80%; min-height: 260px;
		.popArrow {
			width: 60px;
			&.prev {left: 0;}
			&.next {right: 0;}
		}
		.content_pop {
			min-height: 260px;
			.video-pop {min-height: 260px; height: 260px;}
		}
		.close_pop {
			right: -25px; top: 0px; width: 25px; height: 25px; background-size: 100%;
		}
	}

	.wrap-tankyou {min-height: 758px;}

	footer {
		.ftop {
			.fl {
				width: 63%;
				.cl {
					margin-bottom: 5px;
					&.mail {border-right:0;}
				}
			}
			.fr {
				width: 27%;
				.fr {
					width: 100%;
				}
			}
		}
	}
  .login{
    height: 100vh;
    .wrapper{
      padding: 0;
      margin: auto 177px;
      top: 50%;
      transform: translateY(-50%);
      .side-img{
        display: none;
      }
      .side-form{
        flex: 0 0 100%;
        max-width: 100%;
        .content-form{
          width: 100%;
          margin: auto 0;
        }
      }
    }
  }
  .mid{
    .close-menu-eca{
      display: block;
    }
    .side-left{
      width: 260px;
      left: -300px;
      opacity: 0;
      transition: 0.2s all ease-out;
      .content{
        padding: 50px 30px;
        nav{
          ul{
            li{
              font-size: 14px;
            }
            &.main-menu{
              padding-bottom: 35px;
            }
          
          }
        }
        .wrap-child{
          .list-child{
            .child-txt{
              h6{
                font-size: 15px;
              }
            }
          }
        }
      }
      &::after{
        display: none;
      }
      &.active{
        left: 0;
        opacity: 1;
        z-index: 999;
        transition: 0.2s all ease-out;
      }
    }
    .nav-mobile-eca{
      display: block;
      opacity: 1;
      transition: 0.2s all ease-out;
    }
    .side-right{
      padding-left: 95px;
      position: relative;
      transition: 0.2s all ease-out;
      &.moveright{
        padding-left: 260px;
        transition: 0.2s all ease-out;
        &:after{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          background: #000000;
          opacity: 0.78;
        }
      }
      .invoice{
        .notes{
          .btn-yellow{
            &.only-mobile{
              display: block;
            }
          }
          .flex-grid{
            flex-flow: wrap;
            .grid-l, .grid-r{
              padding: 0;
              margin-bottom: 10px;
              flex: 0 0 100%;
              max-width: 100%;
            }
            .grid-r{
              order: 1;
              .btn-yellow{
                display: none;
              }
            }
            .grid-l{
              order: 2;
            }
          }
        }
      }
      .summary{
        width: calc( 100% - 95px - 19px );
        right: 19px;
        bottom: 19px;
      }
    }
    .row{
      .column.column-33{
        flex: 0 0 50%;
        max-width: 50%;
      } 
    }
  }
  .eca-top{
    .row{
			.column{
				margin-bottom: 20px;
			}
      .column.column-30,.column.column-20{
        flex: 0 0 50%;
        max-width: 50%;
      }
			.column.column-10{
				flex: 0 0 100%;
				max-width: 100%;
			}
    }
    .wrapper{
      .container{
        form{
          input[type=text]{
            width: 100%;
          }
        }
        .content-centered{
          display: block;
          .content-img{
            margin: 0 auto;
            margin-bottom: 50px;
          }
        }
      }
    }
  }
  .eca-content{
    .wrapper{
      margin: 0 20px;
      .wrap-rounded{
        .content{
          padding: 20px;
          .list-eca{
            .table-std{
              width: 688px;
              overflow-x: auto;
                /* width */ &::-webkit-scrollbar { height: 6px; overflow: hidden; }
                /* Track */ &::-webkit-scrollbar-track {background: linear-gradient(180deg, #EDEDED 77.89%, rgba(237, 237, 237, 0) 100%); overflow: hidden;cursor: pointer;}
                /* Handle */ &::-webkit-scrollbar-thumb {background: #0F6961;  overflow: hidden;cursor: pointer;}
                /* Handle on hover */ &::-webkit-scrollbar-thumb:hover {background: #0F6961; overflow: hidden}
            }
          }
          .list-eca-foot{
            .btn-yellow{
              width: 195px;
            }
          }
        }
      }
    }
  }
}